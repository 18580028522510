import React from 'react';
import { Seo } from 'src/public/components/Seo';

const NotFoundPage = () => (
  <div>
    <Seo title="404" />
    <h1>Strona, której szukasz, nie została znaleziona</h1>
  </div>
);

// eslint-disable-next-line import/no-default-export
export default NotFoundPage;
